@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: 'Avenir' !important; */
  /* font-family: system-ui !important; */
  font-family: 'Poppins' !important;
}

:root {
  --black-1: #03060e;
  --gray-1: #5e5f61;
  --gray-2: #5e5f61;
  --gray-3: #a8aaad;
}

.min-h-100 {
  min-height: 100vh;
  overflow: auto;
}

.ant-input,
.ant-input-password,
.ant-input-password {
  height: 40px;
}

.ant-input-password {
  padding: 0px 11px;
  ;
}

.side-login .ant-image {
  width: 100%;
}

.card_login {
  padding-left: 150px !important;
  padding-right: 150px !important;
}

@media screen and (max-width: 1500px) {
  .card_login {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.h-100 {
  height: 100vh;
  overflow: auto;
}

.mb-2 {
  margin-bottom: 20px;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.item-center {
  align-items: center;
}

.w-100 {
  width: 100% !important;
}

.auth-card-height {
  min-height: calc(100vh - 82px);
}

.card-border-radius {
  border-bottom-left-radius: 100px !important;
}

.my-0 {
  margin-block: 0 !important;
}

.custom_menu_sidebar .ant-menu-root .ant-menu-inline {
  border-inline-end: 0px !important;
}

.custom_menu_sidebar {
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.custom_menu_sidebar .ant-menu-item .ant-menu-title-content {
  font-weight: 400;
}

.custom_menu_sidebar .ant-menu-item {
  height: 38px !important;
}

.text-black {
  color: var(--black-1) !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.custom-primary-btn {
  height: 40px;
  padding: 5px 40px;
}

.text-gray {
  color: var(--gray-1) !important;
}

.text-gray-2 {
  color: var(--gray-2) !important;
}

.text-gray-3 {
  color: var(--gray-3) !important;
}

.transaction-card {
  border-color: #eaf3fd !important;
  background-color: #fbfbfb !important;
}

.transaction-card-dark {
  border-color: #143d69 !important;
  background-color: #131c30 !important;
}

.obj-cover {
  object-fit: cover;
}

.theme-light-card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.22)
}

.theme-dark-card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.0)
}

.transaction-card-btn {
  padding: 10px 20px;
}

.custom_table .ant-table-thead .ant-table-cell {
  font-size: 16px;
  font-weight: 500;
}

.bg-white {
  background-color: white;
  padding: 24px;
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.60) !important;
}

.header-search-input-light {
  background-color: transparent !important;
  border: 1px solid #D2D4D8;
  color: black;
  height: 40px !important;
  border-radius: 8px !important;
}

.header-search-input-dark .ant-select-selector {
  border: 1px solid #BEDAF800 !important;
  background: transparent !important;
}

.header-search-input-dark .ant-select-selection-placeholder {
  color: #adadad
}

.header-search-input-light .ant-select-selector {
  border: 1px solid #BEDAF800 !important;
  background: transparent !important;
}

.header-search-input-dark::placeholder {
  color: #adadad
}


.header-search-input-dark {
  background-color: #00000000 !important;
  border: 1px solid #BEDAF8;
  color: white;
  height: 40px !important;
  border-radius: 8px !important;
}

.upload-input-dark .ant-btn {
  background: #00000000 !important;
  border: 1px solid #BEDAF8;
  color: white;
  height: 40px;
  width: 100%;
}

.upload-input-dark .ant-upload-select {
  height: 40px;
  width: 100%;
}

.upload-input-light .ant-btn {
  background: transparent !important;
  border: 1px solid #D2D4D8;
  color: black;
  height: 40px;
  width: 100%;
}

.upload-input-light .ant-upload-select {
  height: 40px;
  width: 100%;
}

.upload-input-light .ant-upload-list {
  margin-top: 10px;
}

.upload-input-dark .ant-upload-list .ant-upload-list-item {
  color: white;
  border: 1px solid transparent !important;
}

.upload-input-dark .ant-upload-list .ant-upload-list-item .anticon-delete {
  color: white !important;
}

.header-search-input-dark input::placeholder,
.header-search-input-dark svg {
  color: #d2d4d8 !important;
}

.layout-content {
  margin: 24px 16px;
  height: calc(100vh - 120px);
  overflow-x: hidden;
  overflow-y: auto;
}


.layout-content::-webkit-scrollbar {
  width: 0px;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just to make scrollbar invisible */
}

/* Hide scrollbar for Firefox */
.layout-content {
  scrollbar-width: none;
  /* Firefox */
}

.ant-list-item {
  padding: 5px 0px !important;
}

.ant-list-item-meta-title {
  font-weight: 400 !important;
}

.split_dropdown {
  height: 32px !important;
}

@media (max-width: 710px) {
  .mb-2 {
    flex-direction: column;
    align-items: stretch;
  }

  .header-search-input-light,
  .header-search-input-dark {
    width: 100%;
  }

  .mb-2 div {
    margin-top: 0px;
  }
}

.book-badge {
  width: 25px;
  height: 25px;
  background: #BB5215;
  color: white;
  text-align: center;
  border-radius: 12.5px;
  padding-top: 2px;
}

.ant-layout {
  background: #E9ECEF;
}

.ant-typography {
  color: #29b8e3 !important;
}

.subscription-typography1 {
  color: #000000 !important;
}

.subscription-typography {
  color: #7AB730 !important
}

.ant-card {
  border-radius: 15px;
}

.scrollable-guides {
  margin-top: 240px;
  height: calc(100vh - 220px);
  overflow-y: scroll;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}

.scrollable-guides::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Opera */
}

@media (min-width: 480px) and (max-width: 575px) {
  .custom-col {
    /* Your extra styling here */
    padding: 0 10px;
  }
}